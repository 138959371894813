import { styled } from "styled-components";

const MenuWrapper = styled.div`
    height: 50px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 1400px;
`;

const MenuItem = styled.button`
    color: white;
    background-color: #75103f;
    border: none;
    margin: 0 10px;
    flex: 1 1 50px;
    font-size: 20px;
    border-radius: 15px;
    box-shadow: 0 0 10px 5px #75103f;

    @media (min-width: 768px) {
        font-size: 30px;
    }
`;

const Menu = () => {
    return (
        <MenuWrapper>
            <MenuItem>Home</MenuItem>
            <MenuItem>Music</MenuItem>
            <MenuItem>Games</MenuItem>
            <MenuItem>About</MenuItem>
        </MenuWrapper>
    );
};

export {
    Menu,
};

