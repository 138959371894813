import { styled } from "styled-components";
import Background from "./assets/images/Background.jpg";
import Banner from "./assets/images/Banner.jpg";
import { Menu } from "./menu/Menu";

const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 20px;
`;

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 50px;
`;

const BackgroundGraphics = styled.img`
  width: 70%;
  height: 70%;

  @media (max-width: 767px) {
    width: 90%;
    height: 90%;
  }

  max-width: 1500px;

  border-radius: 30px;
  box-shadow:
    inset 0 0 50px #fff,      /* inner white */
    inset 20px 0 80px #f0f,   /* inner left magenta short */
    inset -20px 0 80px #0ff,  /* inner right cyan short */
    inset 20px 0 300px #f0f,  /* inner left magenta broad */
    inset -20px 0 300px #0ff, /* inner right cyan broad */
    0 0 50px #fff,            /* outer white */
    -10px 0 80px #f0f,        /* outer left magenta */
    10px 0 80px #0ff;         /* outer right cyan */
`;

const BannerGraphics = styled.img`
  width: 90%;
  max-width: 1200px;
  margin-bottom: 20px;
`;

const ConstructionMessage = styled.h1`
  color: orange;
  font-weight: 600;
`;

const Side = () => {
  return (
    <PageWrapper>
      <BannerGraphics src={Banner} alt="banner" />
      <Menu />
      <ConstructionMessage>! SITE UNDER CONSTRUCTION !</ConstructionMessage>
      <ContentWrapper>
        <BackgroundGraphics src={Background} alt="background" />
      </ContentWrapper>
    </PageWrapper>
  );
}

export default Side;
